<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001634')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable @on-change="changeYmsWarehouseId">
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1005615')" prop="ymsWarehouseBlockId">
              <Select v-model="pageParams.ymsWarehouseBlockId" transfer filterable @on-change="changeYmsWarehouseBlock"
                :disabled="!pageParams.ymsWarehouseId" :placeholder="pageParams.ymsWarehouseId ? $t('key1000086') : $t('key1000086') + `${configCharacters}` + $t('key1000252')">
                <Option v-for="item in reservoirAreaList" :key="item.ymsWarehouseBlockId" :value="item.ymsWarehouseBlockId">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1003546')" prop="ymsWarehouseLocationId">
              <Select v-model="pageParams.ymsWarehouseLocationId" transfer filterable :disabled="!pageParams.ymsWarehouseBlockId"
                :placeholder="pageParams.ymsWarehouseBlockId ? $t('key1000086'): $t('key1005612')">
                <Option v-for="item in locationList" :key="item.ymsWarehouseLocationId" :value="item.ymsWarehouseLocationId">{{ item.code }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <div class="filterItem">
              <Form-item :label="$t('key1000395')">
                <local-tabButton :tabButtonList="commodityStatusList" @selectStatus="commodityStatus"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              :selectStyle="{width: '110px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              inputType="textarea"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" v-once class="mr10" icon="md-refresh">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="inventorySearch" icon="ios-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002419') }}</span>
        <Button-group>
          <Button :type="item.checked ? 'primary' : 'default'"
            v-for="item in sortData"
            @click="clickSortButton(item)"
            :key="item.value">{{ item.label }}
            <Icon type="md-arrow-up" v-if="item.checked && !item.toogle"></Icon>
            <Icon type="md-arrow-down" v-if="item.checked && item.toogle"></Icon>
          </Button>
        </Button-group>
      </div>
      <Dropdown @on-click="changeExportBtn" class="mr15" v-if="getPermission('wmsInventory_exportYms')">
        <Button type="primary">
          <span>{{ $t('key1002221') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002222') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002223') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        border
        max-height="530"
        :loading="tableLoading"
        :columns="tableColumns"
        @on-selection-change="tableChange"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="totalRecords"
        :current="pageParams.pageNum"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--库存事务明细弹窗-->
    <inventoryTransactionDetailsModal ref="inventoryTransaction" systemType="yms"></inventoryTransactionDetailsModal>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1005613')+`${configCharacters}` + $t('key1005614')"
      paramKeyColums="supplierWmsInventoryYmsWarehouseColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {COMMODITYSTATUS} from "@/utils/enum";
import dropDownFilter from "@/components/common/dropDownFilter";
import inventoryTransactionDetailsModal from "@/components/common/inventoryTransactionDetailsModal";
import {getYmsWarehouseBlockList, pageJump, setConfigCharacters} from "@/utils/common";
import exportFieldsModal from "@/components/common/exportFieldsModal";

export default {
  mixins: [Mixin],
  components: {
    dropDownFilter,
    inventoryTransactionDetailsModal, // 库存事务明细组件
    exportFieldsModal
  },
  data() {
    let self = this;
    return {
      printForm: {
        number: 1
      },
      printModel: false,
      selectObj: null,
      pageParamsStatus: false,
      cacheClickVal: 'SKU', // 排序缓存值
      productSku: '', // 当前行的SKU
      productGoodsId: '',
      wareHouseTypeList: [], // 仓库下拉列表
      // 排序的数据
      sortData: [
        {
          label: alias2f085dd487c1465383484787b29fef2c.t('key1003557'),
          value: 'SKU',
          checked: true,
          toogle: true
        }
      ],
      wareId: this.getWarehouseId(),
      pageParams: {
        status: null, // 商品状态(0:停售 1:在售 2:试卖 3:清仓 默认在售)
        ymsSpus: '', // 云卖SPU
        ymsSkus: '', // 云卖SKU
        supplierSkus: '', // 供应商SKU
        supplierSpus: '', // 供应商SPU
        ymsWarehouseId: null,
        ymsWarehouseBlockId: null, // 库区
        ymsWarehouseLocationId: null, // 库位
        orderSeq: 'DESC', // 降序
        orderBy: 'SKU', // 排序的标记
        pageNum: 1, // 默认第一页
        pageSize: 15, // 规定每页显示 15 条数据
      },
      totalRecords: 0, // 共有多少条
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000784'),
          minWidth: 250,
          align: 'center',
          render: (h, params) => {
            return self.tableProductInformation(h, params);
          }
        },
        {
          title: `${setConfigCharacters}SPU`,
          key: 'ymsSpu',
          minWidth: 140,
          align: 'center',
          render(h, params) {
            return h('span', params.row._productInfo._spuInfo.ymsSpu);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000402'),
          key: 'goodsSku',
          minWidth: 120,
          align: 'center',
          render(h, params) {
            return h('span', params.row._productInfo.supplierSku);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000777'),
          key: 'spu',
          minWidth: 120,
          align: 'center',
          render(h, params) {
            return h('span', params.row._productInfo._spuInfo.supplierSpu);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001646'),
          key: 'warehouseName',
          minWidth: 120,
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001622'),
          key: 'warehouseBlockName',
          minWidth: 120,
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001786'),
          key: 'warehouseLocalName',
          minWidth: 120,
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000207'),
          key: 'allottedNumber',
          minWidth: 100,
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000208'),
          key: 'availableNumber',
          minWidth: 100,
          align: 'center',
          render(h, params) {
            let value = params.row.availableNumber;
            return h('div', null, [
              h('span', {
                style: {
                  fontSize: '13px'
                },
                on: {
                  click: () => {
                    // self.editInput(params.row, currentIndex, isEdit);
                  }
                }
              }, value)
            ]);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000278'),
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          render: function (h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1003565'),
                    show: true,
                    clickFn: () => {
                      self.$refs['inventoryTransaction'].initFun(params.row);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      ymsProductCategoryList: [],
      commodityStatusList: COMMODITYSTATUS,
      dropDownInputList: [
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1000402'), value: 1, placeholder: alias2f085dd487c1465383484787b29fef2c.t('key1002513'), parameterKey: 'supplierSkus'},
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1000777'), value: 2, placeholder: alias2f085dd487c1465383484787b29fef2c.t('key1000778'), parameterKey: 'supplierSpus'},
        {title: `${setConfigCharacters}SKU`, value: 3, placeholder: `${alias2f085dd487c1465383484787b29fef2c.t('key1000287')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1000774')}`, parameterKey: 'ymsSkus'},
        {title: `${setConfigCharacters}SPU`, value: 4, placeholder: `${alias2f085dd487c1465383484787b29fef2c.t('key1000287')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1000773')}`, parameterKey: 'ymsSpus'},
      ],
      defaultSelectValue: 1,
      reservoirAreaList: [],
      locationList: [],
      warehouseList: [],
      selectTableData: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000594'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'spu', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000777')},
            {paramKey: 'sku', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000402')},
            {paramKey: 'ymsSpu', paramValue: `${setConfigCharacters}SPU`},
            {paramKey: 'ymsSku', paramValue: `${setConfigCharacters}SKU`},
            {paramKey: 'cnName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1001241')},
            {paramKey: 'ymsWarehouseName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000252')},
            {paramKey: 'ymsWarehouseBlockName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1001622')},
            {paramKey: 'ymsWarehouseLocationName', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1001786')},
            {paramKey: 'allottedNumber', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000207')},
            {paramKey: 'availableNumber', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000208')},
            {paramKey: 'property', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000216'), disabledDrag: true},
            {paramKey: 'propertyValue', paramValue: alias2f085dd487c1465383484787b29fef2c.t('key1000217'), disabledDrag: true},
          ]
        }
      ],
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    filterKeyList() {
      let parameterKeyList = this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
      return parameterKeyList;
    }
  },
  created() {
    let v = this;
    v.$nextTick(() => {
      v.reset();
    });
    v.searchData();
    v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
      if (data && data.length > 0) {
        v.warehouseList = data;
        if (data.length <= 1) {
          v.pageParams.ymsWarehouseId = data[0].ymsWarehouseId;
          v.getReservoirAreaList();
        }
      }
    });
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.searchData();
        v.pageParamsStatus = false;
      }
    }
  },
  methods: {
    saveAvailableQuantity(talgValue, params) {
      let self = this;
      let reg = self.$regular.minPositiveInteger;
      if (reg.test(talgValue)) {
        self.availableQuantity({
          ymsWarehouseLocationId: params.row.ymsWarehouseLocationId,
          ymsProductGoodsId: params.row.ymsProductGoodsId,
          changedNumber: talgValue,
          ymsInventoryId: params.row.ymsInventoryId,
        }, params.row);
      } else {
        self.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1003563'));
      }
    },
    editInput(obj, index, isEdit) {
      let v = this;
      setTimeout(() => {
        v.$nextTick(() => {
          document.getElementsByClassName('availableInput_' + index)[0].childNodes[6].focus();
        });
      }, 300);
      v.$set(obj, '$isEdit', !isEdit);
    },
    // 选取商品状态
    commodityStatus(index, value) {
      let v = this;
      v.pageParams.status = value;
      v.commodityStatusList.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 参数的处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], ['\n']);
        }
      }
      return params;
    },

    // 查询功能
    searchData() {
      let v = this;
      v.tableData = [];
      v.totalRecords = 0;
      v.tableLoading = true;
      v.axios.post(api.post_wmsInventory_queryYmsInventory, v.handleParameter()).then(res => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          let tableData = data.list || [];
          let ymsWarehouseLocationIds = [];
          let ymsProductGoodsIds = [];
          if (tableData.length > 0) {
            tableData.map((item) => {
              ymsWarehouseLocationIds.push(item.ymsWarehouseLocationId);
              ymsProductGoodsIds.push(item.ymsProductGoodsId);
            });
            if (ymsWarehouseLocationIds.length > 0) {
              (async () => {
                let newData = await v.getWarehouseManageInfo(ymsWarehouseLocationIds, tableData, false, api.post_wmsWarehouse_queryYmsWarehouseLocationIds);
                this.tableData = await v.getProductInfoByIds(ymsProductGoodsIds, newData, false, false, false, api.post_productInfo_queryByYmsProductGoodsIds);
                v.updateTableDom('selection');
              })();
            }
          } else {
            v.tableData = tableData;
            v.updateTableDom('selection');
          }
          v.tableData.map((item) => {
            item['$isEdit'] = false;
          });
          v.totalRecords = data.total;
        } else {
          v.tableLoading = false;
        }
      });
    },
    inventorySearch() {
      // 页面查询
      this.pageParams.pageNum = 1;
      this.searchData();
    },
    // 点击排序的按钮
    clickSortButton(data) {
      if (data.value === this.cacheClickVal) {
        data.toogle = !data.toogle;
      }
      this.cacheClickVal = data.value;
      if (data.toogle) {
        this.pageParams.orderSeq = 'DESC';
      } else {
        this.pageParams.orderSeq = 'ASC';
      }
      this.sortData.forEach(function (n) {
        n.checked = data.value === n.value;
      });
      this.pageParams.orderBy = data.value;
      this.searchData();
    },
    // 重置按钮
    reset() {
      this.$refs['pageParams'].resetFields();
      this.commodityStatus(0, null);
      this.changeInput(1);
      this.inventorySearch();
    },
    // 编辑可用数量
    availableQuantity(params, row) {
      let v = this;
      v.tableLoading = true;
      v.axios.put(api.put_ymsInventory, params).then(res => {
        v.tableLoading = false;
        if (res.data.code === 0) {
          // v.pageParamsStatus = true;
          v.$set(row, '$isEdit', !row.$isEdit);
          v.$set(row, 'availableNumber', params.changedNumber);
          v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
        }
      });
    },

    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      data.map((item) => {
        if (item.ymsInventoryId) {
          this.selectTableData.push(item.ymsInventoryId);
        }
      });
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1002224'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1002225'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.ymsInventoryIds = v.selectTableData;
      } else {
        query.ymsInventoryIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_wmsInventory_exportYms, query,
            {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 获取对应的库区数据
    getReservoirAreaList(ymsWarehouseId) {
      let id = ymsWarehouseId || this.pageParams.ymsWarehouseId;
      if (id) {
        getYmsWarehouseBlockList(id, 'supplier').then((newList) => {
          this.reservoirAreaList = newList;
        });
      }
    },
    // 获取对应库位数据
    changeYmsWarehouseBlock(value) {
      this.locationList = [];
      if (value) {
        this.axios.get(api.get_ymsWarehouseLocation_api_queryByYmsWarehouseBlockId + `?ymsWarehouseBlockId=${value}`).then(response => {
          if (response.data.code === 0) {
            this.locationList = response.data.datas;
          }
        });
      }
    },
    // 选择云卖仓库
    changeYmsWarehouseId(value) {
      if (value) {
        this.getReservoirAreaList(value);
      }
    }
  }
};
</script>
<style>
.show-save-btn {
  display: none;
  margin-left: -6px;
}

.ivu-table-cell:hover .show-save-btn {
  display: inline-block;
}
</style>

<style lang="less" scoped>
.batch_style {
  /deep/ .ivu-table-fixed-header thead tr th {
    padding: 0 !important;
  }
}

.dataSort {
  position: relative;
  height: 55px;

  .export_style {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
